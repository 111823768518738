import { graphql } from "gatsby"
import React from "react"

import RichText from "../components/common/RichText"
import Hero, { colors, titleColors, variations } from "../components/Hero/Hero"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import {
  selectAboutPageContent,
  selectProductGroups,
  selectTranslations,
} from "../graphql/selectors"
import s from "./about.module.scss"

const pageBackground = require("../../static/images/backgrounds/about-page.png")

export default function AboutPage(props) {
  const { data, pageContext } = props
  const { langCode, localePath, pagePathsByLangCode } = pageContext
  const translations = selectTranslations(data)
  const { seo, hero, statements } = selectAboutPageContent(data)
  const contentStyle = { backgroundImage: `url(${pageBackground})` }

  return (
    <Layout
      className={s.main}
      i18n={{ ...translations, localePath }}
      langCode={langCode}
      pagePathsByLangCode={pagePathsByLangCode}
      productGroups={selectProductGroups(data)}
      style={contentStyle}
    >
      <SEO {...seo} lang={langCode} />
      <Hero
        color={colors.crusta}
        description={hero.description}
        title={hero.title}
        titleColor={titleColors.bright}
        variation={variations.compact}
      />
      <div className={s.content}>
        <section className={s.statementsList}>
          {statements.map(({ title, description }) => (
            <article className={s.statement} key={title}>
              <h3 className={s.title}>
                <RichText>{title}</RichText>
              </h3>
              <p
                className={s.description}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </article>
          ))}
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AboutPage($locale: String) {
    ...LayoutFragment
    ...AboutPageFragment
  }
`
